class FFComponent {
	constructor(elem)
	{
		this._elem = elem;
	}
	
	static getByElem(elem)
	{
		return $(elem).data('component');
	}
	
	get elem()
	{
		return this._elem;
	}
	
	update()
	{
		this._updateUI();
	}

	init()
	{
		this._initDOM();
	}
	
	_change(prop, val)
	{
		this[prop] = val;
		this.update();
	}
	
	_updateUI()
	{
	}

	_initDOM()
	{
	}
}