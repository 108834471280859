'use strict';

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var FFComponent = function () {
	function FFComponent(elem) {
		_classCallCheck(this, FFComponent);

		this._elem = elem;
	}

	_createClass(FFComponent, [{
		key: 'update',
		value: function update() {
			this._updateUI();
		}
	}, {
		key: 'init',
		value: function init() {
			this._initDOM();
		}
	}, {
		key: '_change',
		value: function _change(prop, val) {
			this[prop] = val;
			this.update();
		}
	}, {
		key: '_updateUI',
		value: function _updateUI() {}
	}, {
		key: '_initDOM',
		value: function _initDOM() {}
	}, {
		key: 'elem',
		get: function get() {
			return this._elem;
		}
	}], [{
		key: 'getByElem',
		value: function getByElem(elem) {
			return $(elem).data('component');
		}
	}]);

	return FFComponent;
}();

